import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RoutingView from './RoutingView';
import StrategyOverview from './RoutingDetails/StrategyDetails/StrategyOverview';
import RoutingDetails from './RoutingDetails';
import StrategyDetails from './RoutingDetails/StrategyDetails';
import UploadDataSetCSV from './RoutingDetails/StrategyDetails/UploadCSV';

function Routing() {
  return (
    <Routes>
      <Route exact path="/" element={<RoutingView />} />
      <Route exact path="/add-new" element={<StrategyOverview />} />
      <Route exact path="/add-new/:merchantId" element={<StrategyOverview />} />
      <Route exact path="/:merchantId" element={<RoutingDetails />} />
      <Route path="/:merchantId/:strategyId" element={<StrategyDetails />} >
        <Route path="strategy-overview" element={<StrategyDetails />} />
        <Route path="data-sets" element={<StrategyDetails />} />
      </Route>
      <Route path="/:merchantId/:strategyId/upload-csv" element={<UploadDataSetCSV />} />
      <Route path="/:merchantId/:strategyId/edit-data-set/:dataSetId" element={<UploadDataSetCSV isEdit={true} />} />
    </Routes>
  );
}

export default Routing;