import React, { useState, useMemo } from 'react';
import { Radar } from 'react-bootstrap-icons';
import { IconData, MessageBar, Pagination } from 'app/components';
import OrderItem from '../../OrderItem';
import { BoxSeam, ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const PackageCard = (props) => {
  const {
    orderId,
    shipment,
    orderPackage,
    numPackages,
    // showCarrierLabelModal,
    showChangeVendorModal,
    showCancelOrderItemModal,
    showUnassignItemsModal,
    showEditQuantityModal,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const lookupOrderItemInfo = (itemId) => {
    return shipment.orderItems.find(item => item.id === itemId);
  };

  // calculate the displayed items for the current page
  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return orderPackage.orderItemIDs.slice(startIndex, endIndex);
  }, [orderPackage.orderItemIDs, currentPage, pageSize]);

  const totalItems = useMemo(() => orderPackage.orderItemIDs.length, [orderPackage.orderItemIDs]);

  return (
    <div className={`package-card ${numPackages > 1 ? 'multiple' : ''}`} key={orderPackage.id}>
      {numPackages > 1 && (
        <div className="package-card-header">
          <div className="icon-title">
            <BoxSeam />
            Package {orderPackage.shortId}
          </div>
          {shipment.packages[0].carrierTrackingNumber && (
            <IconData
              label={shipment.packages[0].carrierTrackingNumber}
              icon={<Radar />}
              onClick={shipment.packages[0].carrierLink ? () => {
                let carrierLink = shipment.packages[0].carrierLink;
                // Ensure the URL is absolute, add 'https://' if it doesn't start with a protocol
                if (!carrierLink.match(/^https?:\/\//)) {
                  carrierLink = 'https://' + carrierLink;
                }
                window.open(carrierLink, '_blank');
              } : null}
            />
          )}
          {/* <IconData
            label="View Shipping Label"
            icon={<Receipt />}
            onClick={() => {showCarrierLabelModal(true)}}
          /> */}
        </div>
      )}
      {displayedItems.map((item) => {
        const itemInfo = lookupOrderItemInfo(item);
        if (!itemInfo) {
          return (
            <MessageBar key={item} color="yellow" icon={<ExclamationCircle />}>
              {`Order item ${item} not found`}
            </MessageBar>
          )
        }
        return (
          <OrderItem
            key={itemInfo.id}
            orderId={orderId}
            itemInfo={itemInfo}
            shipmentId={shipment.id}
            showChangeVendorModal={showChangeVendorModal}
            showCancelOrderItemModal={showCancelOrderItemModal}
            showUnassignItemsModal={showUnassignItemsModal}
            showEditQuantityModal={showEditQuantityModal}
          />
        );
      })}
      
      {totalItems > 5 && (
        <div className="pagination-container">
          <Pagination
            totalItems={totalItems}
            pageSizes={[5, 10, 20, 50]}
            hideBelow={5}
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={(page, newPageSize) => {
              setCurrentPage(page);
              setPageSize(newPageSize);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PackageCard;