import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MessageBar } from 'app/components';
import { orderDetailsSelector, orderDetailsErrorsSelector } from 'app/store/selectors/order';
import { shipmentsSelector } from 'app/store/selectors/shipment';
import UnassignedItemsCard from './Cards/UnassignedItemsCard';
import DetailsCard from './Cards/DetailsCard';
import ShipmentCard from './Cards/ShipmentCard';
import CancelShipmentModal from './Modals/CancelShipmentModal';
import CancelOrderItemModal from './Modals/CancelOrderItemModal';
import RerouteOrderItemModal from './Modals/RerouteOrderItemModal';
import RejectShipmentModal from './Modals/RejectShipmentModal';
import CreateShipmentModal from './Modals/CreateShipmentModal';
import UnassignItemsModal from './Modals/UnassignItemsModal';
import EditQuantityModal from './Modals/EditQuantityModal';
import FullImagePreview from './Modals/FullImagePreview';
import EditTrackingModal from './Modals/EditTrackingModal';
import TrackingInfoModal from './Modals/TrackingInfoModal';
import ResubmitShipmentModal from './Modals/ResubmitShipmentModal';
import ChangeShipmentStatusModal from './Modals/ChangeShipmentStatusModal';
import defaultCarrierLabel from 'app/assets/images/defaultCarrierLabel.png';
import './index.scss';

const SummaryView = () => {
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsErrors = useSelector(orderDetailsErrorsSelector);
  const shipments = useSelector(shipmentsSelector);

  const [menuState, setMenuState] = useState({});
  const [changeVendorModal, showChangeVendorModal] = useState(null);
  const [cancelShipmentModal, showCancelShipmentModal] = useState(null);
  const [cancelOrderItemModal, showCancelOrderItemModal] = useState(null);
  const [createShipmentModal, showCreateShipmentModal] = useState(null);
  const [carrierLabelModal, showCarrierLabelModal] = useState(null);
  const [rejectShipmentModal, showRejectShipmentModal] = useState(null);
  const [unassignItemsModal, showUnassignItemsModal] = useState(null);
  const [editTrackingModal, showEditTrackingModal] = useState(null);
  const [changeShipmentStatusModal, showChangeShipmentStatusModal] = useState(null);
  const [trackingInfoModal, showTrackingInfoModal] = useState(null);
  const [resubmitShipmentModal, showResubmitShipmentModal] = useState(null);
  const [editQuantityModal, showEditQuantityModal] = useState(null);

  const toggleMenu = (menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
  };

  const { parsedShipments, unmatchedOrderItems } = useMemo(() => {
    if (!orderDetails || !shipments) {
      return { parsedShipments: [], unmatchedOrderItems: [] };
    }
  
    const parsedShipments = [];
    // create a copy of the order items. We will look through this array to determine which items are not assigned to a shipment
    const unmatchedOrderItems = orderDetails.items.map(item => ({ ...item }));
  
    shipments
      // filter out archived shipments
      .filter(shipment => shipment.shipmentStatus !== 'Archived')
      // then for each shipment
      .forEach((shipment) => {
        // create a copy of the shipment and remove the orderItems
        const shipmentWithItems = { ...shipment, orderItems: [] };
  
        // then for each shipment, loop over every orderItemID within it
        shipment.orderItems.forEach((shipmentItem) => {
          // find the orderItem in the orderDetails
          const orderItemIndex = unmatchedOrderItems.findIndex(orderItem => orderItem.id === shipmentItem.id);
          
          if (orderItemIndex !== -1) {
            const orderItem = unmatchedOrderItems[orderItemIndex];
            const quantityToMove = shipmentItem.quantity; 
  
            // check if the quantity in the shipment is greater than or equal to the quantity in the order item
            if (quantityToMove >= orderItem.quantity) {
              // move the entire quantity to the shipment
              shipmentWithItems.orderItems.push({
                ...orderItem,
                quantity: orderItem.quantity // Move the full remaining quantity
              });
              // remove the item from unmatchedOrderItems
              unmatchedOrderItems.splice(orderItemIndex, 1);
            } else {
              // partially move the quantity to the shipment
              shipmentWithItems.orderItems.push({
                ...orderItem,
                quantity: quantityToMove // move only part of the quantity
              });
              // reduce the quantity in unmatchedOrderItems
              orderItem.quantity -= quantityToMove;
            }
          } else {
            console.error(`Order item ${shipmentItem.id} not found in order items`);
          }
        });
  
        parsedShipments.push(shipmentWithItems);
      });
  
    // if unmatchedOrderItems has more than 5 items, collapse the card by default
    if (unmatchedOrderItems.length > 5) {
      setMenuState((prevState) => ({
        ...prevState,
        unassignedItems: true
      }));
    }
  
    return { parsedShipments, unmatchedOrderItems };
  }, [shipments, orderDetails]);

  return (
    <div className="summary-view">
      {orderDetails && shipments && (
        <div className="summary-cards">
          <DetailsCard orderDetails={orderDetails} shipments={shipments} />
          {unmatchedOrderItems.length > 0 && (
            <UnassignedItemsCard
              orderId={orderDetails.id}
              orderItems={unmatchedOrderItems} 
              menuState={menuState} 
              toggleMenu={toggleMenu}
              showCancelOrderItemModal={data => showCancelOrderItemModal(data)}
              showCreateShipmentModal={data => showCreateShipmentModal(data)}
              showEditQuantityModal={data => showEditQuantityModal(data)}
            />
          )}
          {parsedShipments.map((shipment) => (
            <ShipmentCard
              key={shipment.id}
              orderId={orderDetails.id}
              orderDetails={orderDetails}
              shipment={shipment}
              menuState={menuState}
              toggleMenu={toggleMenu}
              showCancelShipmentModal={data => showCancelShipmentModal(data)}
              showCancelOrderItemModal={data => showCancelOrderItemModal(data)}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={data => showChangeVendorModal(data)}
              showRejectShipmentModal={data => showRejectShipmentModal(data)}
              showUnassignItemsModal={data => showUnassignItemsModal(data)}
              showEditTrackingModal={data => showEditTrackingModal(data)}
              showChangeShipmentStatusModal={data => showChangeShipmentStatusModal(data)}
              showTrackingInfoModal={data => showTrackingInfoModal(data)}
              showResubmitShipmentModal={data => showResubmitShipmentModal(data)}
              showEditQuantityModal={data => showEditQuantityModal(data)}
            />  
          ))}
        </div>
      )}
      {carrierLabelModal && (
        <FullImagePreview
          imageUrl={defaultCarrierLabel}
          onClose={() => showCarrierLabelModal(false)}
        />
      )}
      {changeVendorModal && (
        <RerouteOrderItemModal
          orderId={orderDetails.id}
          shipmentId={changeVendorModal.shipmentId}
          shipmentShortId={changeVendorModal.shipmentShortId}
          itemInfo={changeVendorModal.itemInfo}
          showChangeVendorModal={showChangeVendorModal}
        />
      )}
      {cancelShipmentModal && (
        <CancelShipmentModal
          orderId={orderDetails.id}
          shipment={cancelShipmentModal}
          showCancelShipmentModal={showCancelShipmentModal}
        />
      )}
      {createShipmentModal && (
        <CreateShipmentModal
          orderId={orderDetails.id}
          orderItems={createShipmentModal.orderItems}
          showCreateShipmentModal={showCreateShipmentModal}
        />
      )}
      {cancelOrderItemModal && (
        <CancelOrderItemModal
          orderId={orderDetails.id}
          itemInfo={cancelOrderItemModal.itemInfo}
          showCancelOrderItemModal={showCancelOrderItemModal}
        />
      )}
      {rejectShipmentModal && (
        <RejectShipmentModal
          shipment={rejectShipmentModal}
          orderId={orderDetails.id}
          showRejectShipmentModal={showRejectShipmentModal}
        />
      )}
      {resubmitShipmentModal && (
        <ResubmitShipmentModal
          orderId={orderDetails.id}
          shipmentId={resubmitShipmentModal.shipmentId}
          showResubmitShipmentModal={showResubmitShipmentModal}
        />
      )}
      {unassignItemsModal && (
        <UnassignItemsModal
          orderId={orderDetails.id}
          shipmentId={unassignItemsModal.shipmentId}
          itemIds={unassignItemsModal.itemIds}
          singleItemShortId={unassignItemsModal.singleItemShortId}
          isAllItems={unassignItemsModal.isAllItems}
          showUnassignItemsModal={showUnassignItemsModal}
        />
      )}
      {editTrackingModal && (
        <EditTrackingModal
          shipment={editTrackingModal.shipment}
          showEditTrackingModal={showEditTrackingModal}
        />
      )}
      {editQuantityModal && (
        <EditQuantityModal
          orderId={orderDetails.id}
          itemInfo={editQuantityModal.itemInfo}
          showEditQuantityModal={showEditQuantityModal}
        />
      )}
      {changeShipmentStatusModal && (
        <ChangeShipmentStatusModal
          orderId={orderDetails.id}
          shipment={changeShipmentStatusModal}
          showChangeShipmentStatusModal={showChangeShipmentStatusModal}
        />
      )}
      {trackingInfoModal && (
        <TrackingInfoModal
          trackingInfo={trackingInfoModal}
          showTrackingInfoModal={showTrackingInfoModal}
        />
      )}
      {orderDetailsErrors && (
        <MessageBar color="yellow">
          An error occurred while fetching order data
        </MessageBar>
      )}
    </div>
  )
}

export default SummaryView;