import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOrderItem } from 'app/store/actions/order';
import { Modal, Button, ButtonIcon, Input } from 'app/components';
import { PlusLg, DashLg } from 'react-bootstrap-icons';
import './index.scss';

const EditQuantityModal = props => {
  const { orderId, itemInfo, showEditQuantityModal } = props;
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(itemInfo.quantity);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setQuantity(value);
    }
  };

  const isSaveDisabled = !quantity || Number(quantity) <= 0 || /^\d+$/.test(quantity) && Number(quantity) === 0;

  return (
    <Modal
      className="edit-quantity-modal"
      hideButtons={true}
      onClose={() => { showEditQuantityModal(null) }}
    >
      <div className="edit-quantity-modal-title">{`Edit Item ${itemInfo.shortId}`}</div>
      <div className="edit-quantity-modal-content">
        <ButtonIcon icon={<DashLg />} onClick={() => setQuantity(Math.max(0, quantity - 1))} />
        <Input
          label="Edit Quantity"
          name="quantity"
          value={quantity}
          type="number"
          onChange={handleQuantityChange}
        />
        <ButtonIcon icon={<PlusLg />} onClick={() => setQuantity(quantity + 1)} />
      </div>
      <div className="action-buttons">
        <Button 
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => showEditQuantityModal(null)}
        />
        <Button 
          variant="primary"
          size="small"
          label="Save Changes"
          onClick={() => {
            const data = {
              quantity
            };

            dispatch(updateOrderItem({orderId, itemId: itemInfo.id, data}));
            showEditQuantityModal(null);
          }}
          disabled={isSaveDisabled}
        />
      </div>
    </Modal>
  );
}

export default EditQuantityModal;
