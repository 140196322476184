
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { roleDetailsSelector, roleDetailsLoadingSelector, roleDetailsErrorsSelector, resourcesDataSelector, resourcesErrorsSelector, resourcesLoadingSelector, updateRoleLoadingSelector, currentUserSelector } from 'app/store/selectors/user';
import { getRoleDetails, createRole, updateRole, updateRoleName, getResources } from 'app/store/actions/user';
import { Button, Card, DataPoint, LoadingAnimation, Input, ButtonMenu, ButtonIcon, Checkbox, MessageBar } from 'app/components';
import { ArrowLeft } from 'react-bootstrap-icons';
import { areStringArraysEqual, formatUsersPermissionsName, formatUsersResourceName, formatUsersRolesName, isUserSystemAdmin } from '../utils';
import { usePermission } from 'app/permissions';
import './index.scss';

const RoleDetails = () => {
  const { roleId } = useParams();

  const submitFormRef = useRef(null);
  const [editMode, setEditMode] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roleDetails = useSelector(roleDetailsSelector);
  const roleDetailsLoading = useSelector(roleDetailsLoadingSelector);
  const roleDetailsErrorsFound = useSelector(roleDetailsErrorsSelector);

  const resources = useSelector(resourcesDataSelector);
  const resourcesLoading = useSelector(resourcesLoadingSelector);
  const resourcesErrorsFound = useSelector(resourcesErrorsSelector);

  const updateRoleLoading = useSelector(updateRoleLoadingSelector);
  const currentUser = useSelector(currentUserSelector);

  // get user permissions
  const canManageRoles = usePermission('users', 'roles_create');

  const isAdmin = isUserSystemAdmin(currentUser);

  useEffect(() => {
    if (roleId && (!roleDetails || roleDetails.id !== roleId)) {
      dispatch(getRoleDetails(roleId));
    } else {
      dispatch(getResources());
    }
  }, []);

  const displayHeader = () => {
    const label = roleId
      ? roleDetails ? (!isAdmin && roleDetails?.isSystem ? "View Role" : "Edit Role") : null
      : "Create Role";

    return label;
  }

  const onRoleCreated = () => {
    navigate(`/admin/users/roles`);
  }

  const onRoleUpdated = () => {
    navigate(`/admin/users/roles`);
  }

  const renderSaveButton = () => {
    if (!canManageRoles) return null;

    const label = roleId
      ? roleDetails ? (!isAdmin && roleDetails?.isSystem ? null : "Save Changes") : null
      : "Create Role";

    if (!label) return null;

    return (
      <Button
        variant="primary"
        size="medium"
        label={label}
        onClick={() => submitFormRef.current?.handleSubmit()}
      />
    );
  };

  return (
    <div className="role-details">
      {(roleDetailsLoading || resourcesLoading || updateRoleLoading) && <LoadingAnimation />}
      <div className='gooten-card header-card'>
        <div className="role-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/users/roles')} />
          {displayHeader()}
        </div>
        {!editMode && roleDetails && canManageRoles ? (
          <ButtonMenu
            label="Role Options"
            variant="primary"
            size="mediu"
            options={[
              { value: 'Edit Role', label: 'Edit Role', onClick: () => setEditMode(true) },

            ]}
            width={148}
          />
        ) : renderSaveButton()}
      </div>
      <div>
        {(roleDetailsErrorsFound) && (
          <MessageBar color="yellow">
            Role Data failed to load.  Refresh the page to try again.
          </MessageBar>
        )}
        {
          (resourcesErrorsFound) && (
            <MessageBar color="yellow">
              Resources Data failed to load.  Refresh the page to try again.
            </MessageBar>
          )}
      </div>
      {(roleDetails || !roleId) && (
        <>
          <Card className={`role-details-card ${editMode ? 'edit-mode' : ''}`}>
            <Card.Header>
              Role Details
            </Card.Header>
            <Card.Body>
              {!editMode && roleDetails && (
                <>
                  <div className="role-details-inputs view-mode">
                    <DataPoint title="Role Name" data={formatUsersRolesName(roleDetails?.name)} />
                  </div>
                </>
              )}
              {editMode && (
                <Formik
                  innerRef={submitFormRef}
                  enableReinitialize
                  initialValues={{
                    roleId: roleDetails?.id || '',
                    name: roleDetails?.name ? formatUsersRolesName(roleDetails?.name) : '',
                    permissionsSet: roleDetails?.permissionSet || resources?.map(permission => ({
                      ...permission, associatedPermissions: []
                    })),
                    permissions: roleDetails?.permissionSet?.map(permission => ({
                      "resourceId": permission.resourceId,
                      "permissions": permission.associatedPermissions,
                    })) || {},
                  }}
                  validationSchema={() =>
                    object().shape({
                      name: string()
                        .required('Enter a valid name')
                        .matches(/^[a-zA-Z0-9 ]*$/, 'Name can only contain letters, numbers, and spaces')
                    })
                  }
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    if (roleDetails) {

                      // check do we need to update role permissions...
                      const existingPermissions = roleDetails?.permissionSet?.map(permission => permission?.associatedPermissions?.map(ap => ap.description)).flat();
                      const newPermissions = values?.permissionsSet?.map(permission => permission?.associatedPermissions?.map(ap => ap.description)).flat();

                      if (!areStringArraysEqual(existingPermissions, newPermissions)) {
                        dispatch(updateRole({ roleId: roleDetails.id, values, cb: onRoleUpdated }));
                      }

                      // check do we need to update role name...
                      if (formatUsersRolesName(roleDetails?.name) !== values.name) {
                        dispatch(updateRoleName({ roleId: roleDetails.id, name: values.name }));
                      }
                    } else {
                      // create new role
                      dispatch(createRole({ values, cb: onRoleCreated }));
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    submitCount,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="role-details-inputs">
                        <Input
                          label="Role Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          placeholder="Name"
                          errorMessage={submitCount > 0 && errors.name}
                        />
                      </div>
                      <div className="role-permissions-header" hidden={values.permissionsSet ? false : true}>
                        <div>Select Permissions</div>
                        <div>Add Permissions</div>
                      </div>
                      <div className="role-permissions-details">
                        {values.permissionsSet?.map(permission => (
                          <div key={values.id + permission.resourceId}>
                            <div className="role-permissions-container">
                              <div className="role-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                              <div className="role-permissions-checkbox">
                                <Checkbox size='medium' checked={permission.associatedPermissions?.length == permission.availablePermissions?.length}
                                  disabled={renderSaveButton() == null}
                                  onChange={(e) => {
                                    if (e) {
                                      permission.associatedPermissions = permission.availablePermissions;
                                    } else {
                                      permission.associatedPermissions = [];
                                    }
                                    let permissions = values.permissionsSet?.map(permission => ({
                                      "resourceId": permission.resourceId,
                                      "permissions": permission.associatedPermissions,
                                    }));
                                    setFieldValue('permissions', permissions);
                                  }} />
                              </div>
                            </div>
                            <div className="role-permissions">
                              {permission?.availablePermissions?.map(available =>
                              (
                                <div key={values.id + permission.resourceId + available.name} className="role-permissions-line" >
                                  <div className="role-permissions-title">{available.description?.length ? available.description : formatUsersPermissionsName(available.name)}</div>
                                  <div className="role-permissions-checkbox">
                                    <Checkbox size='medium' disabled={renderSaveButton() == null} checked={permission.associatedPermissions?.map(ap => ap.name).includes(available.name)} onChange={() => {
                                      if (permission.associatedPermissions?.map(ap => ap.name).includes(available.name)) {
                                        permission.associatedPermissions = permission.associatedPermissions?.filter(q => q.name !== available.name);
                                      } else {
                                        permission.associatedPermissions?.push(available);
                                      }
                                      let permissions = values.permissionsSet?.map(permission => ({
                                        "resourceId": permission.resourceId,
                                        "permissions": permission.associatedPermissions,
                                      }));
                                      setFieldValue('permissions', permissions);
                                    }} />
                                  </div>
                                </div>
                              )
                              )}</div>
                          </div>))}
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  )
}

export default RoleDetails;